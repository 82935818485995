.portfolio-page {
    box-sizing: border-box;

    p {

        &::after,
        &::before {
            display: none;
        }
    }

    .page-title {
        margin-top: -33rem;
    }

    .images-container {
        width: 80%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35rem;
        display: grid;
        gap: 50px;
        grid-template-columns: auto auto auto auto ;
        justify-content: center;
        overflow-wrap: hidden;

        @media screen and (max-width:1650px) {
            grid-template-columns: auto auto auto;
                        margin-top: 35rem;
        }
        @media screen and (max-width:1290px) {
            grid-template-columns: auto auto auto;
            margin-top: 35rem;
                    justify-content: start;
                            gap: 20px;
        }
        @media screen and (max-width:1200px) {
            grid-template-columns: auto auto auto;
            margin-top: 5%;
                                justify-content: center;
                                        gap: 50px;
        }
        @media screen and (max-width:1130px) {
            grid-template-columns: auto auto;
            margin-top: 5%;
            margin-bottom: 10%;
        }

        @media screen and (max-width:768px) {
            grid-template-columns: auto;
        }
    }


    .image-box {
        background: #000;
        box-shadow: 0 0 20px 0px rgb(33, 170, 163);
        position: relative;
        width: 330px;
        height: 400px;
        overflow: hidden;
        border-radius: 12.5px;
                @media screen and (max-width:300px) {
                            zoom: .8
                    }


        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 10px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(-90deg,
                    rgba(0, 0, 0, 0.665),
                    #05283A);
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
            position: relative;
            padding: 0 10px;

        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffdd003f, #001824);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 01;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}