.text-zone a {
    text-decoration: none;
    color: #bfe4ff;
    font-style: italic ;
    padding: 0 5px;
        &:hover {
                color: #ffd700;
            }
}

.text-zone b {
    color: #ffd700;
    font-size: 15px;
    &:hover {
        color: #bfe4ff;
    }
}



.face8 {
    background: #593D88;
}

.skills {
    width: 30%;
    height: 70%;
    top: 0;
    padding-top: 27%;
    margin-left: -50%;
    position: absolute;
    right: 10rem;
    margin-top: 8rem;
    scale: .9;

    @media screen and (max-width:1200px) {
        position: initial;
        width: 20%;
        margin-left: 50%;
        margin-top: 7%;
        margin-bottom: -20rem;
        display: block;
        box-sizing: border-box;
    }

    @media screen and (max-width:768px) {
        position: initial;
        width: 20%;
        margin-left: 50%;
        margin-top: 25%;
        margin-bottom: -10rem;
        scale: .65;
        display: block;
        box-sizing: border-box;
    }


}


.obj-skills {
    animation-name: spincubes;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    transform-style: preserve-3d;
    transform-origin: 0px 0px 0px;
    margin-left: calc(50% - 100px);
    padding: 20px;
    display: grid;
    gap: 25px;
    grid-template-columns: auto auto auto auto;

    @media screen and (min-width:1200px) {
        gap: 50px;
    }

    .face1 {
        transform: translateZ(-10px);
        color: #dd0031;
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 50s;
        transform-style: preserve-3d;
        transform-origin: 0px 0px 0px;
        margin-left: calc(50% - 100px);
    }

    .face2 {
        transform: rotateY(190deg);
        color: #f06529;
        rotate: 45deg;
        transform: translateZ(50px);
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 25s;
        transform-style: preserve-3d;
        transform-origin: 50px 10px +10px;
        margin-left: calc(50% - 100px);
    }

    .face3 {
        transform: rotateY(-90deg);
        color: #28a4d9;
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 50s;
        transform-style: preserve-3d;
        transform-origin: 50px 10px -10px;
        margin-left: calc(50% - 100px);
    }

    .face4 {
        transform: rotateY(120deg) rotateZ(-90deg);
        color: #5ed4f4;
        rotate: 45deg;
    }

    .face5 {
        transform: rotateZ(90deg);
        color: #efd81d;
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 35s;
        transform-style: preserve-3d;
        transform-origin: 0px 0px 0px;
        margin-left: calc(50% - 100px);
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(10px);
        color: #ec4d28;
        rotate: 45deg;
        transform: translateY(100px) rotateY(-90deg);
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 25s;
        transform-style: preserve-3d;
        transform-origin: 50px 10px -00px;
        margin-left: calc(50% - 100px);
    }

    .face7 {
        transform: rotateY(-90deg) translateZ(100px);
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 60s;
        transform-style: preserve-3d;
        transform-origin: 20px 10px 5px;
        margin-left: calc(50% - 100px);
    }

    .face8 {
        rotate: 45deg;
        transform: rotateX(-90deg) translateZ(50px);
        transform: translateZ(10px);
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 40s;
        transform-style: preserve-3d;
        transform-origin: 5px 10px -20px;
        margin-left: calc(50% - 100px);
    }

    .face9 {
        transform: translateZ(50px) rotateX(-180deg);
        animation-name: spincubes;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 30s;
        transform-style: preserve-3d;
        transform-origin: 30px 10px -50px;
        margin-left: calc(50% - 100px);
    }

    @keyframes spincubes {

        from,
        to {
            transform: rotateX(90deg) rotateY(45deg) rotateZ(45deg);
        }

        16% {
            transform: rotateY(180px) rotateZ(180deg);
        }

        33% {
            transform: rotateZ(90deg) rotateX(180deg);
        }

        50% {
            transform: rotateX(180deg) rotateZ(180deg);
        }

        66% {
            transform: rotateX(70deg) rotateZ(90deg);
        }

        83% {
            transform: rotateX(-190deg);
        }
    }

    div {
        width: 100px;
        height: 100px;
        border: 1px solid #ccccccc0;
        text-align: center;
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 50px 0px rgb(0, 255, 242);
        animation: rotateOut 6s infinite;

    }

    .face9 {

        img {
            width: 200px;
        }
    }
}