html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 10px;
  
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px rgb(29, 29, 29);
  border-radius: 50px;

}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffd900da;
  border-radius: 10px;
  
}
body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #05283a;
  display: block;
  max-width: 100vw;
  overflow-x: hidden;
}



@media screen and (max-width: 1200px) {
  body {
    padding: 10px;
    
  }
}