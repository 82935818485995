.page {
  width: 100%;
  height: 100vh;
}

.top-tags {
  bottom: auto;
  top: 35px;

}


.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 28px;
  font-family: 'La Belle Aurore', cursive;
}
.bottom-tags{
    color: #ffd700;
  opacity: 0.6;
  position: absolute;
  left: 120px;
  font-size: 28px;
  font-family: 'La Belle Aurore', cursive;
}
.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 100%;
  min-height: 566px;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.skills-page {
  margin: 10rem 0 ;
  padding: 10rem 0 ;
      &:before {
          content: '<section>';
          font-family: 'La Belle Aurore', cursive;
          color: #abdbf4;
          font-size: 22px;
          position: absolute;
          top: 2rem;
          left: 100px;
          opacity: 0.6;
          line-height: 18px;
        }
            &:after {
              content: '<section/>';
              font-family: 'La Belle Aurore', cursive;
              color: #abdbf4;
              font-size: 22px;
              line-height: 18px;
              position: absolute;
              left: 100px;
              bottom: 2rem;
              opacity: 0.6;
            }
        
    h1 {
      font-size: 44px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      position: relative;
      margin-bottom: 70px;
      left: -10px;
      letter-spacing: 5px;

      &:before {
        content: '<h2>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 20px;
        position: absolute;
        margin-top: -15px;
        left: 0px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h2/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 20px;
        line-height: 18px;
        position: absolute;
        left: -10px;
        bottom: -25px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 17px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      line-height: 2;
      max-width: fit-content;
      animation: pulse 1s;
      margin-bottom: 40px;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.3s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.5s;
      }
            &:before {
        content: '<p>';
        font-family: 'La Belle Aurore', cursive;
        color: #aaf5bc;
        font-size: 18px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
                margin: 20px 20px 10px;

      }
            &:after {
        content: '<p/>';
        font-family: 'La Belle Aurore', cursive;
        color: #abf4bd;
        font-size: 18px;
        line-height: 18px;
        left: -30px;
        bottom: -25px;
        margin: 10px 20px 20px;
        opacity: 0.6;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
@media screen and (min-width: 1201px) {

.container.portfolio-page{
  padding-bottom:135rem ;
  .page-title{
    margin-top: -45rem;
  }
}

}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }
.top-tags{
  display: block;
  margin-top: 8rem;
}
  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.contact-page,
    &.about-page,
    &.portfolio-page,
    &.skills-page {
      &:before {
          content: '<section>';
          font-family: 'La Belle Aurore', cursive;
          color: #abdbf4;
          font-size: 24px;
          position: absolute;
          top: -15px;
          left: 0px;
          opacity: 0.60;
          line-height: 18px;
        }
      
        &:after {
          content: '<section/>';
          font-family: 'La Belle Aurore', cursive;
          color: #abdbf4;
          font-size: 24px;
          line-height: 18px;
          position: absolute;
          left: -10px;
          bottom: -15px;
          margin-left: 20px;
          opacity: 0.60;
        }


&.container.portfolio-page{
      .page-title {
          margin-top: 0rem;
        }
}

      h1{
        font-size: 25px;
        margin-left: 00px;
        margin-top: 20px;
      }
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }



&.about-page {
  padding-bottom: 35rem;

}
  }
.skills,
.stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
  }
}